import {
	type ErrorResponse,
	isRouteErrorResponse,
	useParams,
	useRouteError,
} from '@remix-run/react'
import { captureRemixErrorBoundaryError } from '@sentry/remix'
import { getErrorMessage } from '#app/utils/misc.tsx'
import { css } from '#styled-system/css/css.js'

type StatusHandler = (info: {
	error: ErrorResponse
	params: Record<string, string | undefined>
}) => JSX.Element | null

export function GeneralErrorBoundary({
	defaultStatusHandler = ({ error }) => (
		<p>
			{error.status} {error.data}
		</p>
	),
	statusHandlers,
	unexpectedErrorHandler = error => <p>{getErrorMessage(error)}</p>,
}: {
	defaultStatusHandler?: StatusHandler
	statusHandlers?: Record<number, StatusHandler>
	unexpectedErrorHandler?: (error: unknown) => JSX.Element | null
}) {
	const error = useRouteError()
	captureRemixErrorBoundaryError(error)
	const params = useParams()

	if (typeof document !== 'undefined') {
		console.error(error)
	}

	return (
		<div
			className={css({
				padding: '64px',
			})}
		>
			<div
				className={css({
					marginTop: '8px',
					fontSize: '36px',
					lineHeight: '40px',
					fontWeight: '800',
					letterSpacing: '-0.025em',
					color: 'gray12',
					'@media(min-width:640px)': {
						fontSize: '48px',
						lineHeight: '1',
					},
				})}
			>
				{isRouteErrorResponse(error)
					? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
							error,
							params,
						})
					: unexpectedErrorHandler(error)}
			</div>
			<p
				className={css({
					marginTop: '8px',
				})}
			>
				Please, report the error to us via the email below.
			</p>
			<div
				className={css({
					marginTop: '24px',
				})}
			>
				<a
					className={css({
						fontWeight: '500',
						color: 'gray11',
						textDecoration: 'underline',
						textUnderlineOffset: '1px',
						'&:hover': {
							color: 'gray12',
						},
					})}
					// eslint-disable-next-line remix-react-routes/use-link-for-routes
					href="mailto:contact@notionicons.so"
				>
					contact@notionicons.so
				</a>
			</div>
		</div>
	)
}
